const { lazy } = require("react")

const Pages = {
  Signin: lazy(() => import("Packer/Pages/Auth/Signin")),
  RestorePassword: lazy(() => import("Packer/Pages/Auth/RestorePassword")),

  PackerAttachCurierV2: lazy(() => import("Packer/Pages/AttachCurierV2")),

  PackerListsV2: lazy(() => import("Packer/Pages/ListsV2")),

  Containers: lazy(() => import("Packer/Pages/PackageContainers")),
}

export default Pages
